$('.info_slider').slick({
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  fade: true,
  prevArrow: '<div class="prev"><img src="img/left.png" alt="photo"></div>',
  nextArrow: '<div class="next"><img src="img/right.png" alt="photo"></div>',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
		arrows: false
      }
    },
    {
      breakpoint: 560,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
		arrows: false
      }
    }
  ]
});

$('.info_slider_new .container').slick({
  dots: false,
  infinite: true,
  speed: 1000,
  autoplaySpeed: 900,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: '<div class="prev"><img src="img/sld_bntt.png" alt="photo"></div>',
  nextArrow: '<div class="next"><img src="img/sld_bnt.png" alt="photo"></div>',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
		arrows: false
      }
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
		arrows: false
      }
    },
    {
      breakpoint: 560,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
		arrows: false
      }
    }
  ]
});

$('.info_slider_employees .slider').slick({
  dots: false,
  infinite: true,
  speed: 2000,
  autoplay: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: '<div class="prev"><img src="img/sld_bntt.png" alt="photo"></div>',
  nextArrow: '<div class="next"><img src="img/sld_bnt.png" alt="photo"></div>',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
		arrows: false
      }
    },
    {
      breakpoint: 560,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
		arrows: false
      }
    }
  ]
});

$(function(){ 
	var buttons = $(".info_footer .arrow");
	fimenu();
	
    function fimenu(e){
		var scrolltop = $(this).scrollTop();
		
		if(scrolltop > 350){
		}else{
		}
	}
	
	$(document).on("scroll", fimenu);
	
	buttons.on("click", function(){
		$("html, body").animate({
			scrollTop: 0
		}, 2000);
	});
	
	$(document).on("scroll", fimenu);
	
	$(function(){
		var burgerMenu = $(".info_header .navbar-header .menu-trigger");
		var content = $(".info_header .burger-menu");
		
		burgerMenu.on("click", function(e){
			e.preventDefault();
			burgerMenu.toggleClass("active");
			$(".info_header .burger-menu .drop .dropdown").removeClass("actives");
			content.slideToggle();
		});
	});
	
	$(function(){
		var burgerMenu = $(".info_extra_menu .navbar-header .menu_first");
		var content = $(".info_extra_menu .burger_first");
		
		burgerMenu.on("click", function(e){
			e.preventDefault();
			burgerMenu.toggleClass("active");
			$(".info_extra_menu .burger_first .drop .dropdown").removeClass("actives");
			content.slideToggle();
		});
	});
	
	$(function(){
		var burgerMenu = $(".info_extra_menu .navbar-header .menu_second");
		var content = $(".info_extra_menu .burger_second");
		
		burgerMenu.on("click", function(e){
			e.preventDefault();
			burgerMenu.toggleClass("active");
			$(".info_extra_menu .burger_second .drop .dropdown").removeClass("actives");
			content.slideToggle();
		});
	});
	
	$(function(){
		var burgerMenu = $(".info_extra_menu .navbar-header .menu_three");
		var content = $(".info_extra_menu .burger_three");
		
		burgerMenu.on("click", function(e){
			e.preventDefault();
			burgerMenu.toggleClass("active");
			$(".info_extra_menu .burger_three .drop .dropdown").removeClass("actives");
			content.slideToggle();
		});
	});
	
	$(".navigation_container .search").on("click", function(e){
		e.preventDefault();
		$(".navigation_container .form_control").slideToggle();
	});
	
	$(".burger-menu .drop").on("click", function(e){
		e.preventDefault();
		$(".burger-menu .drop .dropdown").addClass("actives");
	});
	
	$(".info_header .information .search").on("click", function(e){
		e.preventDefault();
		$(".info_header .information .form_control").slideToggle();
	});
	
	$(".info_extra_menu .entry .search").on("click", function(e){
		e.preventDefault();
		$(".info_extra_menu .entry .form_control").slideToggle();
	});
	
	$(function(){
		$('.info_internal_blog .question').on('click', function(){
			$(this).next().slideToggle(400);
		});
	});
});